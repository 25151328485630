.buy-nft-middle_offers {
  margin-top: 100px;
  width: 100%;
}

.buy-nft-middle_mint {
  text-align: center;
  margin-right: 200px;
}

.buy-nft-middle_banner {
  margin-top: 80px;
  width: 100%;
}

.buy-nft-middle_offers_txt {
  text-align: center;
}
